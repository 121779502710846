import React from 'react'
import { Link } from 'react-router-dom'

const PaymentSuccess = () => {
  return (
    <div className='d-flex justify-content-center mt-4 text-center'>
        <div className='card w-25'>
            <div className='card-body'>
            <p>Payment successful. Please check your e-mail address for information. Thank you.</p>
            <p>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                >
  <polyline points="20 6 9 17 4 12"></polyline>
</svg>

            </p>
            <div>
                <Link to={"/"} className='btn btn-danger'>Go back to Home</Link>
            </div>
            </div>
        </div>
    </div>
  )
}

export default PaymentSuccess