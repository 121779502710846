import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getPersonalPaymentByGuidId, securePayment } from '../services/api';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import Card from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData
} from "../utils/paymentUtil";

import "react-credit-cards/es/styles-compiled.css";

const PersonalPayment = () => {
  const { guidId } = useParams(); // URL'den "guidId" parametresini çekiyoruz
  const [data, setData] = useState({});
  const [phone, setPhone] = useState('');

  //for credit card
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCVC] = useState('');
  const [issuer, setIssuer] = useState('');
  const [focused, setFocused] = useState('');
  const [formData, setFormData] = useState(null);
  const [responseData, setResponseData] = useState({})

  
  useEffect(() => {
    getPersonalPaymentByGuidId(guidId)
    .then(res => {
      if(res.data.data === null) {
        window.location.href = "/";
      }else{
        setData(res.data.data);
      }
    })
    .catch(err => {
      window.location.href = "/";
    });
  }, [])

  useEffect(() => {
    if(responseData.mid !== undefined) {
      const form = document.getElementById("payment_form");
      form.submit();
    }
  }, [responseData])
  

  const getCurrencyString = (currency) => {
    if(currency === "TL") {
      return "₺";
    }
    if( currency === "US"){
      return "$";
    }
  }

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };

  const handleInputChange = ({ target }) => {
    let value = target.value;
    if (target.name === 'cardNumber') {
      value = formatCreditCardNumber(value);
    } else if (target.name === 'expiry') {
      value = formatExpirationDate(value);
    } else if (target.name === 'cvc') {
      value = formatCVC(value);
    }

    // Update the corresponding state based on the input's name
    switch (target.name) {
      case 'cardNumber':
        setNumber(value);
        break;
      case 'cardHolderName':
        setName(value);
        break;
      case 'expiry':
        setExpiry(value);
        break;
      case 'cvc':
        setCVC(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formElements = [...e.target.elements];
    const newFormData = formElements
      .filter(element => element.name)
      .reduce((acc, element) => {
        acc[element.name] = element.value;
        return acc;
      }, {});

    setFormData(newFormData);
    newFormData.phoneNumber = phone.trim();
    securePayment(newFormData)
    .then(res => {
      setResponseData(res.data)
    })
    .catch(err => console.log(err))
    e.target.reset();
  };
  
  return (
    <div className='personalPayment' key="Payment">
      <div className="App-payment">
        <h1 className='text-center text-white'>Personal Payment</h1>
        <h4 className='text-center text-white mb-3'>{data.serviceName} - {data.amount} {getCurrencyString(data.currency)}</h4>
        <Card
          number={number}
          name={name}
          expiry={expiry}
          cvc={cvc}
          focused={focused}
          callback={handleCallback}
        />
        <form onSubmit={handleSubmit}>
          <input name='paymentId' className='d-none' hidden defaultValue={data.id}/>
          <div className="form-group mb-3">
            <input
              type="tel"
              name="cardNumber"
              className="form-control"
              placeholder="Card Number"
              pattern="[\d| ]{16}"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              value={number}
            />
          </div>

          <div className="form-group mb-3">
            <input
              type="text"
              name="cardHolderName"
              className="form-control"
              placeholder="Name"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              
            />
          </div>

          <div className="row mb-3">
              <div className="col-6">
                <input
                  value={expiry}
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="Expiration date"
                  pattern="\d\d/\d\d"
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  name="cvc"
                  className="form-control"
                  placeholder="CVC"
                  pattern="\d{3}"
                  required
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  value={cvc}
                />
              </div>
          </div>

          <div className="row mb-3">
              <div className="col-6">
                <input
                  defaultValue={data.emailAddress}
                  disabled
                  style={{backgroundColor: '#dddddd'}}
                  type="text"
                  name="emailAddress"
                  className="form-control"
                  placeholder="E-mail address"
                  required
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  name="passportNo"
                  className="form-control"
                  placeholder="Passport no"
                  required
                />
              </div>
          </div>

          <div className="row mb-3">
              <div className="col-6">
                <input
                  type="text"
                  name="country"
                  className="form-control"
                  placeholder="Your country"
                  required
                />
              </div>
              <div className="col-6">
              <PhoneInput
                defaultCountry="gb"
                value={phone}
                onChange={(phone) => setPhone(phone)}
                inputClassName="form-control"
                required
                />
              </div>
          </div>
          {/* <input type="hidden" name="issuer" value={issuer} /> */}
          
          <div className="form-group">
            <button className="btn btn-primary btn-block w-100">PAY</button>
          </div>
        </form>
      </div>

      <form id='payment_form' className='d-none' hidden name="formName" method="post" action="https://posnet.yapikredi.com.tr/3DSWebService/YKBPaymentService" target="YKBWindow"> 
        <input name="mid" id="mid" value={responseData.mid} />
        <input name="posnetID" id="PosnetID" value={responseData.posnetID} />
        <input name="posnetData"  id="posnetData" value={responseData.posnetData} />
        <input name="posnetData2" id="posnetData2" value={responseData.posnetData2} />
        <input name="digest"  id="sign" value={responseData.digest} />
        <input name="merchantReturnURL"  id="merchantReturnURL" value={responseData.merchantReturnURL} />
        <input name="lang" id="lang" value={responseData.lang} />
        <input name="url"  id="url" value={responseData.url} />
        <input name="openANewWindow"  id="openANewWindow" value="1" />
        <input id="submit_btn" type="submit" name="Submit" value="Doğrulama Yap"/>
    </form>
    </div>
  );
}

export default PersonalPayment