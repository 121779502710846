const Loading = ({loading}) => {

    return (
      <div className="loadingWrapper">
        <div className={`app ${loading ? 'loading' : ''}`}>
            {loading && <div className="loading-overlay">
                <p>
                <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        >
        <circle
            cx="50"
            cy="50"
            r="40"
            fill="none"
            stroke="white"
            strokeWidth="4"
            strokeDasharray="126.920, 251.327" /* Yarım dönüşe karşılık gelen strokeDasharray değeri */
            strokeDashoffset="63.460" /* Yarım dönüşün yarıya kadar olan strokeDashoffset değeri */
        />
        </svg>
                </p>
            </div>}
        </div>
      </div>
    );
}

export default Loading