import React from 'react'
import { Link } from 'react-router-dom'

const PaymentError = () => {
  return (
    <div className='d-flex justify-content-center mt-4 text-center'>
        <div className='card w-25'>
            <div className='card-body'>
            <p>Payment failed. Please try again later.</p>
            <p>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                width={50}
                height={50}
                >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </p>
            <div>
                <Link to={"/"} className='btn btn-danger'>Go back to Home</Link>
            </div>
            </div>
        </div>
    </div>
  )
}

export default PaymentError