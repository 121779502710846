import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home'
import PersonalPayment from './pages/personalPayment';
import PaymentError from './pages/paymentError';
import PaymentSuccess from './pages/paymentSuccess';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>

          <Route path="/payment-error" element={<PaymentError />}>
          </Route>
          <Route path="/payment-success" element={<PaymentSuccess />}>
          </Route>

          <Route path="/personal-payment/:guidId" element={<PersonalPayment  />}>
          </Route>
        </Routes>
      </div>

    </BrowserRouter>
  );
}

export default App;
