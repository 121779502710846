import { endpoints } from "../endpoints";
import axios from "axios";

export const getPersonalPaymentByEmailAddress = emailAddress =>  axios.get(endpoints.getPersonalPaymentByEmailAddress + emailAddress);
export const getPersonalPaymentByGuidId = guidId =>  axios.get(endpoints.getPersonalPaymentByGuidId + guidId);

export const securePayment = data => {
    return axios.post(endpoints.securePayment, data);
};

