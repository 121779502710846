import React, { useState } from 'react'
import { getPersonalPaymentByEmailAddress } from '../services/api'
import Loading from '../components/loading'

const Home = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [loading, setLoading] = useState(false)

  const onSubmit = async function (e) {
    e.preventDefault(); 
    setLoading(true);
    const result = await getPersonalPaymentByEmailAddress(emailAddress);
    if(result.data.statusCode !== 200 || result.data.data.length === 0){
      setLoading(false);
      alert("No payment method was found registered to this e-mail address.");
      window.location.reload();
    }

    if(result.data.statusCode === 200 && result.data.data.length > 0) {
      setLoading(false);
      window.location.href = "personal-payment/" + result.data.data[0].guidId;
    }
  }

  return (
    <form className="home" onSubmit={onSubmit}>
      <div className='text-white text-center mt-5'>
        <h4>YETİŞ HEALTHS PERSONAL PAYMENT</h4>
        <img className='mt-4' src="https://res.cloudinary.com/dfl6o4cjk/image/upload/v1679848744/yetishealths/tmp-1-1679848743791_rxerkr.png" width="100" />
      </div>
      <div className="payment_check">
        <div className="login-box">
            <div className="form">
                <div className="user-box">
                <input
                  id="emailAddress"
                  type="email"
                  name="emailAddress"
                  required
                  value={emailAddress}
                  onChange={(event) => setEmailAddress(event.target.value)}
                />                    
                <label>Email Address</label>
                </div>
                <center>
                    <a id="go_payment" onClick={onSubmit}>
                        Go to Payment
                        <span></span>
                    </a>
                </center>
            </div>
        </div>
      </div>
      <Loading loading={loading}/>
    </form>
  )
}

export default Home